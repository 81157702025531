import { purchaseDeal } from '~/domain/deal';
import { defaultOnError } from '~/domain/error';
import { useMutation, useQueryClient } from 'react-query';
import { useVisibility } from '~/shared';
import { VisibilityName } from '~/store';
import { useParams } from '@remix-run/react';

export const usePurchase = () => {
  const { dealId } = useParams();
  const queryCache = useQueryClient();
  const successVisibility = useVisibility(VisibilityName.SUCCESS_REDEEM);
  const { mutate, error, status } = useMutation(purchaseDeal, {
    onError: defaultOnError,
    onSuccess: () => {
      queryCache.invalidateQueries('getMesraBalance');
      successVisibility.open();
    },
  });

  return {
    request: () => mutate(dealId as string),
    purchaseError: error,
    isPurchasing: status === 'loading',
  };
};
