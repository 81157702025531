import { FC, useRef } from 'react';
import * as React from 'react';
import cn from 'classnames';
import { Outlet } from '~/domain/outlet';
import { SelectSheetOption } from '~/components/Form/Select/SelectSheet/SelectSheetOption';

export type DealOutletProps = Pick<Outlet, 'name' | 'address'> & {
  selected?: boolean;
  onSelect?: () => void;
};

export const DealOutlet: FC<React.PropsWithChildren<DealOutletProps>> = ({
  name,
  address,
  selected,
  onSelect,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const content = (
    <>
      <p className="text-regular font-medium text-gray-dark truncate">{name}</p>
      <p className="text-gray-medium text-sm truncate">{address}</p>
    </>
  );

  return (
    <div
      className={cn('py-4', onSelect && 'flex items-center justify-between')}
      onClick={() => {
        inputRef.current?.click();
      }}
    >
      {!onSelect ? (
        content
      ) : (
        <>
          <div className="pr-2 truncate">{content}</div>
          <SelectSheetOption
            ref={inputRef}
            checked={selected}
            onChange={() => onSelect()}
          />
        </>
      )}
    </div>
  );
};
