import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntersectionObserver } from '~/shared/hooks';
import { VisibilityName, visibilitySlice } from '~/store/visibility';

export const useRedeemBarVisibility = () => {
  const dispatch = useDispatch();
  const updateRedeemVisibility = useCallback(
    ({ isIntersecting }: any) =>
      dispatch(
        visibilitySlice.actions.set({
          name: VisibilityName.REDEEM_BAR,
          state: !isIntersecting,
        })
      ),
    [dispatch]
  );

  return useIntersectionObserver({
    rootMargin: '-10%',
    callback: updateRedeemVisibility,
  });
};
