import cn from 'classnames';
import { MixDealRedeemModal } from './MixDealRedeemModal';
import pointsRmSvg from './assets/points-rm.svg';

export type MixDealBannerProps = {
  forMobile?: boolean;
};

export const MixDealBanner = ({ forMobile }: MixDealBannerProps) => {
  return (
    <div
      className={cn(
        'flex flex-row items-center rounded p-0 h-auto bg-gradient-to-t from-[rgba(235,226,252,0.7)] to-[rgba(235,226,252,0.7)]',
        {
          'md:hidden flex md:w-full mb-3': forMobile,
          'hidden md:flex w-full mt-4': !forMobile,
        }
      )}
    >
      <img className="rounded" alt="points-rm" src={pointsRmSvg} height="20" />
      <div className="px-2 py-3 md:px-3 md:py-5 font-inter font-medium text-[14px] leading-[20px] text-[#2D333A] font-normal">
        <span className="font-inter font-medium text-[14px] leading-[20px] text-[#2D333A] font-medium">
          Not enough points?
        </span>{' '}
        Use your Setel Wallet balance to help out with the voucher redemption!
        <MixDealRedeemModal />
      </div>
    </div>
  );
};
