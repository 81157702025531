import { FC } from 'react';
import * as React from 'react';
import cn from 'classnames';
import { DealPrice } from '~/domain/deal';
import { UnitLabel } from './UnitLabel';

export type CurrentPriceProps = DealPrice & {
  twoLine?: boolean;
};

export const CurrentPrice: FC<React.PropsWithChildren<CurrentPriceProps>> = ({
  twoLine,
  unit,
  currentPrice,
}) => {
  return (
    <div
      className={cn('font-medium text-petronas-brand', {
        'md:text-lg': !twoLine,
        'md:text-2xl': twoLine,
      })}
    >
      <span>
        {new Intl.NumberFormat().format(currentPrice)}
        &nbsp;
        {twoLine && <UnitLabel unit={unit} />}
      </span>
    </div>
  );
};
