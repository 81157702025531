import type { Image } from '../../shared';
import type { VoucherBatch } from '../voucherBatch';
import type { CampaignDetails } from '../campaign';
import type { BadgeDetails } from '../badge';

export type Deal = {
  _id: string;
  leftCount: number;
  price: DealPrice;
  content: DealContent;
  voucherBatch: Omit<VoucherBatch, 'merchant'> &
    Required<Pick<VoucherBatch, 'merchant'>>;
  redemptionCriteriaInfo?: DealRedemptionCriteriaInfo;
  isRedemptionCriteriaUnlocked?: boolean;
};

export type DealPrice = {
  unit: DealPriceUnit;
  amount: number;
  currentPrice: number;
  isDiscountPriceAvailable: boolean;
  isDiscountPriceFree: boolean;
  discounted?: DealPriceDiscounted;
};

export type DealPriceDiscounted = {
  newAmount: number;
  startDate: string;
  endDate: string | null;
};

export enum DealPriceUnit {
  POINTS = 'POINTS',
}

export type DealContent = {
  images: Image[];
  summary: string;
  buttonText: string;
};

type WithUnlock = { isUnlocked?: boolean };

export type DealRedemptionCriteriaInfo = {
  campaign?: Pick<CampaignDetails, '_id' | 'name' | 'linkedBadges'> &
    WithUnlock;
  badge?: BadgeDetails & WithUnlock;
  membershipTier?: any;
};
