import { Icon, Size } from '~/components';
import cn from 'classnames';
import { BadgeCampaignProgress } from '~/domain/badge';
import { Progress } from '~/components/Progress';

type BadgeCampaignProps = {
  campaignProgress: BadgeCampaignProgress;
};

export const BadgeProgress = ({ campaignProgress }: BadgeCampaignProps) => (
  <div className="w-full p-5 bg-white rounded-lg shadow">
    <div className="font-bold text-link text-gray-light leading-4 uppercase tracking-widest">
      {campaignProgress.sectionTitle}
    </div>
    {campaignProgress.items.map((el, index) => (
      <div key={index} className={cn({ 'mt-3': !index, 'mt-4': !!index })}>
        <div className="text-gray-dark leading-5">{el.description}</div>
        <div className="text-gray-light text-sm mt-1 flex items-center">
          {el.current >= el.target && (
            <Icon
              size={Size.SMALL}
              className="inline cursor-pointer mr-1 text-success"
              name="miniCheck"
            />
          )}
          {el.progressLabel}
        </div>
        <div className="mt-2">
          <Progress
            percent={getProgressPercentage(el.current, el.target)}
            color="bg-brand"
            className="h-3"
          />
        </div>
      </div>
    ))}
  </div>
);

const getProgressPercentage = (current: number, target: number): number => {
  if (!target) return 0;
  const progress = Math.round((current / target) * 100);
  if (progress >= 100) return 100;
  return progress;
};
