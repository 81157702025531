import { Icon, Size } from '~/components';
import { FC } from 'react';
import * as React from 'react';
import { BadgeBenefits } from '~/domain/badge';

type BadgeBenefitsProps = {
  benefits: BadgeBenefits;
};

export const BadgeBenefit: FC<React.PropsWithChildren<BadgeBenefitsProps>> = ({
  benefits,
}) => {
  return (
    <div className="w-full p-5 bg-white rounded-lg shadow">
      <div className="font-bold text-link text-gray-light leading-4 uppercase tracking-widest">
        {benefits.sectionTitle}
      </div>
      <ul className="list-none mt-3">
        {benefits.items.map((ele, index) => (
          <li key={index} className="flex flex-row items-start">
            <div className="mr-3 mt-1">
              <Icon size={Size.SMALL} name="status" className="text-brand" />
            </div>

            <div className="text-gray-dark col-span-11 leading-6">
              {ele.title}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
