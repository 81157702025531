import { CrossIcon, IconButton, Modal } from '@setel/web-ui';
import { Link } from '~/components';
import { useVisibility } from '~/shared';
import { VisibilityName } from '~/store';
import { Ccode } from '~/components/Ccode';
import { DownloadViaSMSForm } from '../Home/DownloadViaSMSForm';
import { DownloadAppButtons } from '../Home/DownloadAppButtons';

export const MixDealRedeemModal = () => {
  const modal = useVisibility(VisibilityName.MIX_DEAL_REDEEM_LEARN_MORE_LINK);
  const hidePopup = () => {
    modal.close();
  };
  return (
    <>
      <Link className="ml-0.5 no-underline cursor-pointer" onClick={modal.open}>
        Learn more
      </Link>
      <Modal
        isOpen={modal.isOpen}
        onDismiss={hidePopup}
        aria-label="Welcome to Mesra Rewards"
        className="md:w-[980px] md:rounded-t-2xl overflow-hidden bg-white"
      >
        <Modal.Body className="p-0 relative">
          {/* Make the container flex-col for mobile and flex-row for larger screens */}
          <div className="relative flex flex-col md:flex-row md:rounded-t-lg h-auto md:h-[476px]">
            {/* Image container - moves to the top on mobile */}
            <div className="md:hidden">
              <img
                alt="mixed-deal-mobile"
                loading="lazy"
                decoding="async"
                src="/assets/mixed-deal/mixed-deal-modal-mobile.svg"
                className="block w-full"
                width={400}
                height={476}
              />
            </div>

            {/* Text and content section */}
            <div className="w-full md:w-[580px]">
              <div className="p-5 md:p-[50px]">
                <h2 className="font-title text-[24px] md:text-[28px] text-[#2D333A] font-semibold leading-[32px] md:leading-[38px] text-center">
                  The new way to redeem <br />
                  vouchers in the Setel app
                </h2>
                <h3 className="font-inter text-[16px] md:text-[18px] font-normal leading-[24px] md:leading-[27px] text-center text-[#525B65] mt-4">
                  No more worrying about insufficient points. Combine your Mesra
                  Rewards points with Setel Wallet balance to redeem vouchers.
                </h3>
                <div className="flex items-center lg:items-start flex-col">
                  <Ccode className="lg:mb-3 md:w-[335px] lg:w-auto mt-[24px]" />
                  <div className="hidden lg:block">
                    <DownloadViaSMSForm id="download-app-mix-deal" />
                  </div>
                  <DownloadAppButtons className="mt-5" />
                </div>
              </div>
            </div>

            {/* Image for larger screens */}
            <div className="hidden md:block">
              <img
                alt="mixed-deal"
                loading="lazy"
                decoding="async"
                src="/assets/mixed-deal/mixed-deal-modal.svg"
                className="block w-full"
                width={400}
                height={476}
              />
            </div>

            <IconButton
              aria-label="Close popup"
              onClick={hidePopup}
              className="text-gray-500 absolute right-0 mt-4 mr-4"
              children={<CrossIcon />}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
