import { FC } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DealPriceUnit } from '~/domain/deal';

export type UnitLabelProps = {
  unit: DealPriceUnit;
};

export const UnitLabel: FC<React.PropsWithChildren<UnitLabelProps>> = ({
  unit,
}) => {
  const { t } = useTranslation();
  const Label: Record<DealPriceUnit, string> = {
    [DealPriceUnit.POINTS]: t('unitLabel.points'),
  };

  return <span>{Label[unit]}</span>;
};
