import { createCDNUtils, CDNImageModifiers } from '@setel/web-ui';
import { useVariables, WebVariableType } from '~/domain/variable';
import { runTimeConfig } from '~/shared/runtime-config';

export type { CDNImageModifiers };
export const { toCDNImageUrl } = createCDNUtils(runTimeConfig.IMAGE_CDN_URL);

export function useCDNImageUrl(
  ...args: Parameters<typeof toCDNImageUrl>
): string {
  const [src, modifiers] = args;
  const { data } = useVariables();
  // controlled by variable
  return data?.[WebVariableType.PREFER_CDN_IMAGE_RESIZE]?.value
    ? toCDNImageUrl(src, modifiers)
    : src;
}
