import cx from 'classnames';
import { Modal, BareButton, ChevronRightIcon } from '@setel/web-ui';
import { useVisibility } from '~/shared';
import { VisibilityName } from '~/store/visibility';
import { DealOutlets } from '.';

type DealOutletModalProps = {
  outletCount: number;
  voucherBatchId: string;
  label?: string;
  modalTitle?: string;
  className?: string;
};

export const DealOutletModal = ({
  outletCount,
  voucherBatchId,
  label,
  modalTitle,
  className,
}: DealOutletModalProps) => {
  const outletsModal = useVisibility(VisibilityName.OUTLETS);

  return !outletCount ? null : (
    <>
      <BareButton
        className={cx('mt-2', className)}
        rightIcon={<ChevronRightIcon />}
        onClick={outletsModal.open}
      >
        {label ??
          `View ${outletCount} ${
            outletCount > 1 ? 'outlets' : 'outlet'
          } available`}
      </BareButton>
      <Modal
        header={modalTitle ?? 'Available outlets'}
        isOpen={outletsModal.isOpen}
        onDismiss={outletsModal.close}
      >
        <Modal.Body className="pr-0 py-0 overflow-hidden rounded-2xl">
          <section className="overflow-y-auto h-full md:h-128 pr-10">
            <DealOutlets voucherBatchId={voucherBatchId} />
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};
