import { Merchant } from '~/domain/merchant';
import { Image } from '~/shared';

export enum DisplayAs {
  TEXT = 'text',
  BARCODE_AND_TEXT = 'barcode_and_text',
}

export type VoucherBatchContent = {
  title?: string;
  howToClaim?: string[];
  tac?: string[];
  description?: string;
  thumbnail?: Image;
};

export type VoucherBatch = {
  _id: string;
  content: VoucherBatchContent;
  displayAs: DisplayAs;
  merchant?: Merchant;
  outletCount: number;
  showQRCode?: boolean;
};
