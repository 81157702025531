import { TransactionTypes } from './card.type';

export const prefixes = (type: TransactionTypes) => {
  return (
    {
      [TransactionTypes.EARN as string]: ['+', 'Earned'],
      [TransactionTypes.EXTERNAL_EARN as string]: ['+', 'Earned'],
      [TransactionTypes.REDEEM as string]: ['-', 'Redeemed'],
      [TransactionTypes.EXTERNAL_REDEEM as string]: ['-', 'Redeemed'],
      [TransactionTypes.REDEEM_CAPTURE as string]: ['-', 'Redeemed'],
      [TransactionTypes.REDEEM_REVERSAL as string]: ['+', 'Reversal'],
      [TransactionTypes.REDEEM_VOID as string]: ['+', 'Void'],
    }[type] || ['', '']
  );
};
