import { Icon, Size } from '~/components';
import cn from 'classnames';
import { FC, useState } from 'react';
import { BadgeTermsAndConditions } from '~/domain/badge';
import Linkify from '~/components/Linkify';
import { Link } from '~/components/Link';

type BadgeTermsConditionsProps = {
  termsAndConditions: BadgeTermsAndConditions;
};

export const BadgeTermsConditions: FC<
  React.PropsWithChildren<BadgeTermsConditionsProps>
> = ({ termsAndConditions }) => {
  const [isTermsOpened, setIsTermsOpened] = useState(false);

  return (
    <div className="w-full p-5 bg-white rounded-lg shadow">
      <div className="flex justify-between font-bold text-link text-gray-light leading-4 uppercase tracking-widest">
        <span>{termsAndConditions.sectionTitle}</span>
        <span onClick={() => setIsTermsOpened(!isTermsOpened)}>
          {!isTermsOpened ? (
            <Icon
              size={Size.SMALL}
              className="inline cursor-pointer"
              name="arrow"
            />
          ) : (
            <Icon
              size={Size.SMALL}
              className="inline cursor-pointer"
              name="arrowUp"
            />
          )}
        </span>
      </div>
      <ul
        className={cn(
          'pl-5 list-decimal mt-3 text-gray-dark leading-5 text-regular',
          {
            hidden: !isTermsOpened,
          }
        )}
      >
        {termsAndConditions.items.map((item, index) => (
          <li key={index} className="leading-5 break-words">
            <Linkify
              key={index}
              componentDecorator={(href, text, key) => (
                <Link key={key} href={href}>
                  {text}
                </Link>
              )}
            >
              {typeof item === 'string' ? item : item.value}
            </Linkify>
          </li>
        ))}
      </ul>
    </div>
  );
};
