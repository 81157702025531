import cn from 'classnames';
import { GET_APP_LINK } from '~/features/DownloadAppBanner';
import type { ReactNode } from 'react';
import { CustomView } from 'react-device-detect';
import { useIsMounted, useDeviceDetect } from '~/shared/hooks';

const appStore = (
  <img
    className="w-full h-12 lg:h-auto"
    width={300}
    height={90}
    src="/assets/download-appstore.svg"
    alt="Apple AppStore download logo"
    decoding="async"
  />
);
const playStore = (
  <img
    className="w-full h-12 lg:h-auto"
    width={300}
    height={90}
    src="/assets/download-playstore.svg"
    alt="Google PlayStore download logo"
    decoding="async"
  />
);
const appGallery = (
  <img
    className="w-full h-12 lg:h-auto"
    width={300}
    height={90}
    src="/assets/download-appgallery.svg"
    alt="Huawei AppGallery download logo"
    decoding="async"
  />
);

export const DownloadAppButtons = ({ className }: { className?: string }) => {
  const { isIOS, isAndroid, isHuawei } = useDeviceDetect();
  const isMounted = useIsMounted();
  const isNoneMatch = isMounted && !isIOS && !isAndroid && !isHuawei;

  return (
    <>
      <nav
        className={cn(
          'min-h-[48px] grid grid-flow-col auto-cols-fr gap-3 lg:hidden',
          className
        )}
        aria-busy={!isMounted}
      >
        {isNoneMatch ? (
          <>
            <LinkWrapper>{appStore}</LinkWrapper>
            <LinkWrapper>{playStore}</LinkWrapper>
            <LinkWrapper>{appGallery}</LinkWrapper>
          </>
        ) : (
          <>
            <CustomView renderWithFragment condition={isIOS}>
              <LinkWrapper>{appStore}</LinkWrapper>
            </CustomView>
            <CustomView renderWithFragment condition={isAndroid}>
              <LinkWrapper>{playStore}</LinkWrapper>
            </CustomView>
            <CustomView renderWithFragment condition={isHuawei}>
              <LinkWrapper>{appGallery}</LinkWrapper>
            </CustomView>
          </>
        )}
      </nav>

      <nav
        aria-label="Download Setel app's urls"
        className={cn(
          'hidden lg:grid grid-flow-col auto-cols-fr gap-3',
          className
        )}
      >
        <LinkWrapper>{appStore}</LinkWrapper>
        <LinkWrapper>{playStore}</LinkWrapper>
        <LinkWrapper>{appGallery}</LinkWrapper>
      </nav>
    </>
  );
};

function LinkWrapper({ children }: { children: ReactNode }) {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={GET_APP_LINK}
      className="flex"
    >
      {children}
    </a>
  );
}
