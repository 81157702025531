type BadgeAction = {
  link: string;
  webLink?: string;
  title: string;
};

export type UserBadgeGroup = {
  _id: string;
  name: string;
  description: string;
  action: BadgeAction;
  score: number;
  badges: BadgeDetails[];
};

export type BadgeDetails = {
  badge: string;
  id: string;
  status: UserBadgeStatuses;
  periodIndex: number;
  startDate: Date;
  endDate?: Date;
  group: {
    score: number;
    _id: string;
  };
  action: BadgeAction;
  category: BadgeCategories;
  dependsOnCampaign?: string;
  content: IBadgeContent;
  verificationStatus?: VerificationStatuses;
  unlockedAt?: Date;
};

export type BadgeGalleryResponse = {
  data: UserBadgeGroup[];
};

export type IBadgeContent = {
  title: string;
  summary: string;
  action?: BadgeAction;
  iconUrl: string;
  iconUrls: Record<UserBadgeStatuses, string>;
  collectedDate?: string;
  peopleCollected?: string;
  infoSectionText?: string;
  verification?: WithSectionTitle & {
    form: VerificationForm;
    title: string;
    openButtonText: string;
    statusMessageMap?: Record<VerificationStatuses, string>;
  };
  termsAndConditions: BadgeTermsAndConditions;
  campaignProgress?: BadgeCampaignProgress;
  campaignProgressPercent?: number;
  benefits?: BadgeBenefits;
};

export type BadgeCampaignProgress = WithSectionTitle & {
  items: CampaignProgressItem[];
};

export type BadgeTermsAndConditions = WithSectionTitle & {
  items: (Record<'value', string> | string)[];
};

export type BadgeBenefits = WithSectionTitle & {
  items: BenefitItem[];
};

export interface BenefitItem {
  title: string;
  subtitle?: string;
}

type CampaignProgressItem = {
  target: number;
  current: number;
  progressLabel: string;
  description?: string;
};

type VerificationForm = {
  title: string;
  submitButtonText: string;
  fields: IVerificationField[];
  onSubmitText?: string;
};

type IVerificationField = {
  key: string;
  type: VerificationFieldTypes;
  name: string;
  description?: string;
};

type WithSectionTitle = {
  sectionTitle: string;
};

export enum UserBadgeStatuses {
  LOCKED = 'LOCKED',
  UNLOCKED = 'UNLOCKED',
}

export enum VerificationStatuses {
  BLANK = 'BLANK',
  SUBMITTED = 'SUBMITTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum VerificationFieldTypes {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
}

export enum BadgeCategories {
  OPT_IN = 'OPT_IN',
  MISSION = 'MISSION',
  ACHIEVEMENT = 'ACHIEVEMENT',
}
