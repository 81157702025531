import { CheckInCircleIcon, MiniCloseIcon, BareButton } from '@setel/web-ui';
import cn from 'classnames';
import { FadeIn, IconButton } from '~/components';
import { ProfileRoute } from '~/features/Profile';
import { useEffect } from 'react';
import { useVisibility } from '~/shared';
import { VisibilityName } from '~/store';
import { Link } from '@remix-run/react';

const DEFAULT_VISIBILITY_TIME = 5;

const classes = [
  'fixed top-0 right-0 left-0 md:left-auto md:top-from-header z-30',
  'md:m-10 p-4',
  'bg-white rounded-lg md:shadow-2xl',
  'flex shadow-xs items-center justify-between',
  'border border-opacity-10 border-gray-dark',
];

export const SuccessRedeem = () => {
  const { isOpen: isFullHeader } = useVisibility(
    VisibilityName.MOBILE_DEAL_HEADER
  );

  const successVisibility = useVisibility(VisibilityName.SUCCESS_REDEEM);
  useEffect(() => {
    if (!successVisibility.isOpen) return;
    const timer = setTimeout(
      successVisibility.close,
      DEFAULT_VISIBILITY_TIME * 1000
    );
    return () => {
      clearInterval(timer);
    };
  }, [successVisibility]);

  return (
    <FadeIn show={successVisibility.isOpen}>
      <div
        className={cn(classes, {
          'm-3': !isFullHeader,
          'mx-3 mt-20': isFullHeader,
        })}
      >
        <div className="relative">
          <img src="/assets/spot-gift.svg" alt="" className="w-12 h-12" />
          <CheckInCircleIcon className="text-success absolute bottom-0 right-0 transform w-4 h-4" />
        </div>
        <div className="flex-grow ml-4 md:w-64">
          <p className="text-gray-dark leading-5 mb-1">Deal redeemed</p>
          <Link to={ProfileRoute.VOUCHERS}>
            <BareButton>View voucher</BareButton>
          </Link>
        </div>
        <IconButton
          className="self-start"
          onClick={successVisibility.close}
          children={<MiniCloseIcon className="text-lightgrey" />}
        />
      </div>
    </FadeIn>
  );
};
