import { DealPrice } from '~/domain/deal';
import { useRef } from 'react';
import { useVisibility } from '~/shared';
import { VisibilityName } from '~/store/visibility';
import { Dialog } from '@setel/web-ui';
import { PetronasButton } from '~/components';

type ConfirmRedeemProps = {
  onConfirm(): void;
  price: DealPrice;
};

export const ConfirmRedeem = ({ onConfirm, price }: ConfirmRedeemProps) => {
  const redeemConfirmation = useVisibility(VisibilityName.REDEEM_CONFIRMATION);
  const cancelButtonRef = useRef(null);
  return (
    <Dialog
      isOpen={redeemConfirmation.isOpen}
      onDismiss={redeemConfirmation.close}
      leastDestructiveRef={cancelButtonRef}
    >
      <Dialog.Content header="Get this voucher">
        {price.isDiscountPriceFree ? (
          <>Redeem for FREE</>
        ) : (
          <>
            Redeem using&nbsp;
            {new Intl.NumberFormat().format(price.currentPrice)} points
          </>
        )}
      </Dialog.Content>
      <Dialog.Footer>
        <PetronasButton
          onClick={redeemConfirmation.close}
          variant="outline"
          ref={cancelButtonRef}
        >
          CANCEL
        </PetronasButton>
        <PetronasButton
          onClick={() => {
            onConfirm();
            redeemConfirmation.close();
          }}
        >
          CONFIRM
        </PetronasButton>
      </Dialog.Footer>
    </Dialog>
  );
};
