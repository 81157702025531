import { Icon, ImageRegular, EmptyVoucherIcon, Info } from '~/components';
import cn from 'classnames';
import * as React from 'react';
import { trackUserEvent, useVisibility } from '~/shared';
import { VisibilityName } from '~/store';
import { useBadgeDetails } from './hooks/useBadgeGallery';
import { BadgeTermsConditions } from './BadgeTermsConditions';
import { BadgeBenefit } from './BadgeBenefits';
import { BadgeProgress } from './BadgeProgress';
import { EVENT_TYPES } from '~/shared/analytics.events';
import { CircularProgress } from '~/components/Progress';
import { Modal, Loader } from '@setel/web-ui';
import AnimateHeight from 'react-animate-height';
import { useTranslation } from 'react-i18next';

type BadgeModalProps = {
  badgeId: string;
  periodIndex?: number;
  onClose: () => void;
};

const styles = {
  /* default 4px, custom larger 6px for badge details popup */

  /* 6px gap between progress ring vs image border */
  badgeInProgressPadding: 'p-[6px]',
  /* larger default width & height 130px */
  badgeDefaultDiameter: 'w-[130px] h-[130px]',
  /* shrink badge to 94 = 130 - 36 */
  /* 36 = (6 + 6 + 6) x 2 sides */
  /* 1st 6px badgeInProgressPadding */
  /* 2nd 6px progress ring thickness */
  /* 3rd 6px gap between progress ring vs image */
  badgeInProgressDiameter: 'w-[94px]',
};

export const BadgeModal = ({
  badgeId,
  periodIndex,
  onClose,
}: BadgeModalProps) => {
  const { t } = useTranslation();
  const modal = useVisibility(VisibilityName.BADGE_DETAILS);
  const {
    data: badgeDetailsResponse,
    isLoading,
    isError,
  } = useBadgeDetails(badgeId, periodIndex);
  const hasBadgeID = badgeId.length !== 0;

  React.useEffect(() => {
    if (hasBadgeID) modal.open();
  }, [modal, hasBadgeID]);

  const badgeIsInProgress =
    [0, 100].includes(
      badgeDetailsResponse?.content.campaignProgressPercent ?? 0
    ) === false;

  if (badgeDetailsResponse) {
    trackUserEvent(EVENT_TYPES.WEB_GAMIFICATION_BADGE_VIEW_BADGE_DETAILS, {
      badge_id: badgeId,
      badge_title: badgeDetailsResponse.content.title,
      badge_campaign_id: badgeDetailsResponse.dependsOnCampaign,
    });
  }

  if (hasBadgeID === false) return <></>;
  return (
    <Modal
      isOpen={modal.isOpen}
      onDismiss={onClose}
      noHeaderBorder
      aria-label="Badge details"
    >
      <Modal.Header className="bg-gray-light-badge" />
      <Modal.Body className="bg-gray-light-badge p-0 overflow-hidden rounded-b-2xl">
        <section
          className={cn(
            'md:max-h-screen80 px-5 pb-5',
            !isLoading && 'overflow-auto'
          )}
        >
          <div className="w-full m-auto">
            {(isLoading && (
              <div className="flex justify-center h-screen md:h-36">
                <Loader type="spinner-blue" />
              </div>
            )) ||
              (isError && (
                <Info
                  largeTitle
                  svg={EmptyVoucherIcon}
                  title={t('badgeModal.error.title')}
                  subTitle={t('badgeModal.error.subtitle')}
                />
              )) || (
                <div
                  className={cn(
                    'flex items-center m-auto relative',
                    styles.badgeDefaultDiameter,
                    badgeIsInProgress && styles.badgeInProgressPadding
                  )}
                >
                  {badgeIsInProgress && (
                    <CircularProgress
                      progress={
                        badgeDetailsResponse?.content.campaignProgressPercent ||
                        0
                      }
                      size={118}
                      strokeWidth={6}
                    />
                  )}
                  <ImageRegular
                    src={badgeDetailsResponse?.content.iconUrl}
                    className={cn(
                      'm-auto',
                      badgeIsInProgress
                        ? styles.badgeInProgressDiameter
                        : styles.badgeDefaultDiameter
                    )}
                    altTitle={badgeDetailsResponse?.content.title}
                  />
                </div>
              )}
            <AnimateHeight
              height={badgeDetailsResponse ? 'auto' : 0}
              duration={250}
            >
              <div className="space-y-5">
                <div className="px-4">
                  <div className="mt-3 font-medium text-gray-dark text-center text-2xl leading-8">
                    {badgeDetailsResponse?.content.title}
                  </div>
                  <div className="mt-1 text-gray-dark text-center leading-6">
                    {badgeDetailsResponse?.content.summary}
                  </div>
                  {badgeDetailsResponse?.content?.action?.webLink && (
                    <div className="mt-1 text-brand text-center leading-6">
                      <a
                        href={badgeDetailsResponse?.content.action.webLink}
                        rel="noopener noreferrer"
                      >
                        {badgeDetailsResponse?.content.action.title}
                      </a>
                    </div>
                  )}
                </div>

                {badgeDetailsResponse?.content.peopleCollected && (
                  <div className="mt-3 text-gray-medium text-center leading-5 flex justify-center">
                    <Icon className="h-5 w-5 mr-2" name="user" />
                    <span>{badgeDetailsResponse?.content.peopleCollected}</span>
                  </div>
                )}

                {badgeDetailsResponse?.content.collectedDate && (
                  <div className="mt-2 text-gray-medium text-center leading-5 flex justify-center">
                    <Icon className="h-5 w-5 mr-2" name="clock" />
                    <span>{badgeDetailsResponse?.content.collectedDate}</span>
                  </div>
                )}

                {badgeDetailsResponse &&
                  badgeDetailsResponse.content &&
                  badgeDetailsResponse.content.benefits && (
                    <BadgeBenefit
                      benefits={badgeDetailsResponse?.content.benefits}
                    />
                  )}

                {badgeDetailsResponse &&
                  badgeDetailsResponse.content &&
                  badgeDetailsResponse.content.campaignProgress && (
                    <BadgeProgress
                      campaignProgress={
                        badgeDetailsResponse?.content.campaignProgress
                      }
                    />
                  )}

                {badgeDetailsResponse &&
                  badgeDetailsResponse.content &&
                  badgeDetailsResponse.content.termsAndConditions && (
                    <BadgeTermsConditions
                      termsAndConditions={
                        badgeDetailsResponse?.content.termsAndConditions
                      }
                    />
                  )}
              </div>
            </AnimateHeight>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};
