import Qs from 'qs';
import { apiClient } from '../../shared/axios';
import { HttpStatus } from '../../shared/httpStatus';
import { getErrorStatusCode, ValidationError } from '../error';
import {
  GetTransactionsParams,
  Mesra,
  MesraBalance,
  MesraTransaction,
  MesraTransactionItem,
  SearchMesraResponse,
  TransactionStatuses,
  TransactionTypes,
  SwitchToPhysicalCardParams,
  IdIdentifier,
  IcIdentifier,
  OriginalSearchMesraResponse,
  SearchMesraCardResponse,
} from './card.type';

export const SHOW_NOTIFICATION_MESRA = 'show_notification_card';

export const getShowLinkMesraStorageName = (cardId?: string): string =>
  [SHOW_NOTIFICATION_MESRA, cardId].join('_');

export const getCard = () => {
  return apiClient.get<Mesra>('/loyalty/cards').then((resp) => {
    setShowLinkMesraNotificationIfNotExists(resp.data);
    return resp.data;
  });
};

export const getTransactions = (query?: GetTransactionsParams) => {
  const params = {
    limit: 10,
    statuses: [
      TransactionStatuses.SUCCESSFUL,
      TransactionStatuses.PENDING,
      TransactionStatuses.FAILED,
    ],
    types: [
      TransactionTypes.EARN,
      TransactionTypes.REDEEM,
      TransactionTypes.EXTERNAL_EARN,
      TransactionTypes.EXTERNAL_REDEEM,
      TransactionTypes.REDEEM_CAPTURE,
      TransactionTypes.REDEEM_REVERSAL,
      TransactionTypes.REDEEM_VOID,
      TransactionTypes.ADJUSTMENT,
      TransactionTypes.TRANSFER,
    ],
    ...query,
  };
  return apiClient
    .get<MesraTransaction>('/loyalty/cards/transactions', {
      params,
      paramsSerializer: (params) => {
        return Qs.stringify(params, { arrayFormat: 'repeat' });
      },
    })
    .then((resp) => resp.data);
};

export const getTransactionsById = (id: string) => {
  return apiClient
    .get<MesraTransactionItem>(`/loyalty/transactions/${id}`)
    .then((resp) => resp.data);
};

export const getMesraBalance = () => {
  return apiClient
    .get<MesraBalance>('/loyalty/cards/myBalance')
    .then((resp) => resp.data);
};

export const getCardsByIc = (
  params: IdIdentifier
): Promise<SearchMesraCardResponse> => {
  return apiClient
    .get<SearchMesraCardResponse>('/loyalty/cards/searchLoyaltyCards', {
      params: idNumberToIc(params),
    })
    .then((resp) => ({
      ...icNumberToId(resp.data),
      ...resp.data,
    }))
    .catch((error) => {
      if ([HttpStatus.BAD_REQUEST].includes(getErrorStatusCode(error))) {
        throw new ValidationError({
          idNumber: [error.response?.data.message as string],
        });
      }

      throw error;
    });
};

export const searchCard = (
  params: IdIdentifier
): Promise<SearchMesraResponse> => {
  return apiClient
    .get<OriginalSearchMesraResponse>('/loyalty/cards/webSearchLoyaltyCards', {
      params: idNumberToIc(params),
    })
    .then((resp) => ({
      ...icNumberToId(resp.data),
      ...resp.data,
    }))
    .catch((error) => {
      if ([HttpStatus.BAD_REQUEST].includes(getErrorStatusCode(error))) {
        throw new ValidationError({
          idNumber: [error.response?.data.message as string],
        });
      }

      throw error;
    });
};

export const activateMesra = (data: IdIdentifier) => {
  return apiClient
    .put<Mesra>('/loyalty/cards', {}, { params: data })
    .then((resp) => {
      hideLinkMesraNotification(resp.data.cardNumber);
      return resp.data;
    })
    .catch((error) => {
      if (getErrorStatusCode(error) === HttpStatus.UNPROCESSABLE_ENTITY) {
        return error.response.data;
      }

      if ([HttpStatus.BAD_REQUEST].includes(getErrorStatusCode(error))) {
        throw new ValidationError({
          idNumber: [error.response?.data.message as string],
        });
      }

      throw error;
    });
};

export const switchToPhysicalCard = (data: SwitchToPhysicalCardParams) => {
  return apiClient
    .post('/loyalty/cards/webSwitchToPhysicalCard', data)
    .then((resp) => {
      hideLinkMesraNotification(resp.data.cardNumber);
      return resp.data;
    })
    .catch((error) => {
      if (
        [
          HttpStatus.BAD_REQUEST,
          HttpStatus.NOT_FOUND,
          HttpStatus.CONFLICT,
        ].includes(getErrorStatusCode(error))
      ) {
        const messageError = error.response?.data.message;
        throw new ValidationError(
          messageError.includes('identity number / type')
            ? {
                idNumber: [messageError as string],
                idType: [messageError as string],
              }
            : {
                cardNumber: [messageError as string],
              }
        );
      }

      throw error;
    });
};

export const setShowLinkMesraNotificationIfNotExists = ({
  id,
  isPhysicalCard,
}: Mesra) => {
  const storageName = getShowLinkMesraStorageName(id);
  if (localStorage.getItem(storageName) === null) {
    localStorage.setItem(storageName, String(!isPhysicalCard));
  }
};

export const isLinkMesraNotificationVisible = (
  cardId: string = ''
): boolean => {
  return localStorage.getItem(getShowLinkMesraStorageName(cardId)) === 'true';
};

export const getMesraNotificationVisibleValue = (
  cardId: string = ''
): string | null => {
  return localStorage.getItem(getShowLinkMesraStorageName(cardId));
};

export const hideLinkMesraNotification = (cardId: string = '') => {
  localStorage.setItem(getShowLinkMesraStorageName(cardId), String(false));
};

export const icNumberToId = ({
  icNumber,
  icType,
}: IcIdentifier): IdIdentifier => ({
  idNumber: icNumber,
  idType: icType,
});

const idNumberToIc = ({ idNumber, idType }: IdIdentifier): IcIdentifier => ({
  icNumber: idNumber,
  icType: idType,
});
