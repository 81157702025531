import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useIntersectionObserver } from '~/shared/hooks';
import { VisibilityName, visibilitySlice } from '~/store/visibility';

export const useHeaderView = () => {
  const dispatch = useDispatch();

  const updateHeaderView = useCallback(
    ({ isIntersecting }: any) =>
      dispatch(
        visibilitySlice.actions.set({
          name: VisibilityName.MOBILE_DEAL_HEADER,
          state: !isIntersecting,
        })
      ),
    [dispatch]
  );
  return useIntersectionObserver({
    rootMargin: '-10%',
    callback: updateHeaderView,
  });
};
