import { apiClient } from '~/shared/axios';
import { Deal, DealPrice, DealRedemptionCriteriaInfo } from './deal.type';
import { throwErrorData } from '~/domain/error';

export const getDealById = (
  dealId: string,
  { isPublic }: { isPublic?: boolean } = {}
) =>
  apiClient
    .get<Deal>(`/deals/deals/${dealId}/details`, {
      public: isPublic,
    })
    .then((resp) => ({
      ...resp.data,
      price: getCurrentDealPrice(resp.data.price),
      isRedemptionCriteriaUnlocked: isRedemptionCriteriaUnlocked(
        resp.data?.redemptionCriteriaInfo
      ),
    }));

export const purchaseDeal = (dealId: string) =>
  apiClient
    .post(`/deals/deals/${dealId}/deal-orders`)
    .then((resp) => resp.data)
    .catch(throwErrorData);

export const getCurrentDealPrice = (price: DealPrice): DealPrice => {
  const now = new Date();
  const isDiscountPriceAvailable =
    !!price.discounted &&
    now > new Date(price.discounted.startDate) &&
    (!price.discounted.endDate || now < new Date(price.discounted.endDate));
  return {
    ...price,
    currentPrice: isDiscountPriceAvailable
      ? (price.discounted?.newAmount as number)
      : price.amount,
    isDiscountPriceAvailable,
    isDiscountPriceFree:
      (isDiscountPriceAvailable && price.discounted?.newAmount === 0) ||
      !price.amount,
  };
};

export const isRedemptionCriteriaUnlocked = (
  criteria?: DealRedemptionCriteriaInfo
) => {
  // eligible when no criteria found
  if (
    !criteria ||
    (!criteria.campaign && !criteria.badge && !criteria.membershipTier)
  ) {
    return true;
  }
  // checks for each criteria's unlocked status
  if (
    criteria.campaign?.isUnlocked ||
    criteria.badge?.isUnlocked ||
    criteria.membershipTier?.isUnlocked
  ) {
    return true;
  }

  return false;
};

export const getDealRedemptionCriteriaDisplay = (
  criteria: DealRedemptionCriteriaInfo
): { text?: string; type: string } | null => {
  // TODO: CV-97: conditional name/type membership later on
  const { campaign, badge } = criteria;

  if (Boolean(campaign)) {
    return {
      text: campaign?.linkedBadges?.[0]?.content.title,
      // for campaign, we require a badge (that is linked with campaign)
      type: 'badge',
    };
  }

  if (Boolean(badge)) {
    return {
      text: badge?.content.title,
      type: 'badge',
    };
  }

  return null;
};
