import { FC } from 'react';
import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

export type SoldOutOverlayProps = {
  isSoldOut: boolean;
  rounded?: boolean;
};

export const SoldOutOverlay: FC<
  React.PropsWithChildren<SoldOutOverlayProps>
> = ({ isSoldOut, rounded }) => {
  const { t } = useTranslation();

  if (!isSoldOut) return null;

  return (
    <div
      className={cn(
        'absolute bg-gray-900 bg-opacity-65 w-full h-full top-0 flex items-center justify-center',
        {
          'md:rounded-lg': rounded,
        }
      )}
    >
      <span className="text-regular font-medium text-white-off">
        {t('soldOutOverlay.soldOut')}
      </span>
    </div>
  );
};
