import cn from 'classnames';
import { BgColor } from '../theme';

export type ProgressProps = {
  percent: number;
  color?: BgColor;
  className?: string;
};

export const Progress = ({
  color = 'bg-brand',
  percent,
  className,
}: ProgressProps) => {
  return (
    <div className={cn('rounded-lg w-full bg-white-off h-2', className)}>
      <div
        className={`${color} rounded-lg text-xs leading-none text-center text-white h-full`}
        style={{ width: `${percent}%` }}
      />
    </div>
  );
};
