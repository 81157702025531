import { IdentityType } from '../profile';

export enum MesraStatus {
  ISSUED = 'issued',
  ACTIVE = 'active',
  FROZEN = 'frozen',
  TEMPORARILY_FROZEN = 'temporarilyFrozen',
  CLOSED = 'closed',
}

export enum VendorStatus {
  ISSUED = 'ISSUED',
  ACTIVE = 'ACTIVE',
}

export enum MesraIssuer {
  SETEL = 'setel',
  PETRONAS = 'petronas',
}

export enum TransactionStatuses {
  PENDING = 'pending',
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
}

export enum TransactionTypes {
  EARN = 'earn',
  REDEEM = 'redeem',
  EARN_REVERSAL = 'earnReversal',
  EARN_VOID = 'earnVoid',
  REDEEM_REVERSAL = 'redeemReversal',
  REDEEM_VOID = 'redeemVoid',
  EXTERNAL_EARN = 'externalEarn',
  EXTERNAL_REDEEM = 'externalRedeem',
  REDEEM_AUTH = 'redeemAuth',
  REDEEM_CAPTURE = 'redeemCapture',
  ADJUSTMENT = 'adjustment',
  TRANSFER = 'transfer',
}

export type Mesra = {
  id: string;
  cardNumber: string;
  status: MesraStatus;
  vendorStatus: VendorStatus;
  isPhysicalCard: boolean;
  issuedBy: MesraIssuer;
  isPointEarningEnabled: boolean;
  createdAt: string;
  isActive: boolean;
  type: string;
  isPointRedemptionEnabled: boolean;
};

export type CardBalance = {
  pointTotalBalance: number;
};

export type MesraBalance = {
  pointTotalBalance: number;
  pointsExpiryDates: IExpiryDates[];
};

export type IExpiryDates = {
  expiringPointsAmount: number;
  expiringPointsDate: string;
};
export type IcIdentifier = { icNumber: string; icType: IdentityType };
export type IdIdentifier = { idNumber: string; idType: IdentityType };

export type MesraInfo = {
  acctNo: string;
  mediaId: string;
};

export type CardsInfoDB = {
  cardNumber: string;
  isFrozenAt: null | string;
  isTemporarilyFrozenAt: null | string;
};
export type SearchMesraCardResponse = IcIdentifier & {
  cardsInfoDB: [CardsInfoDB];
};

export type OriginalSearchMesraResponse = IcIdentifier & {
  cardsFound: number;
};

export type SearchMesraResponse = IdIdentifier & {
  cardsFound: number;
};

export type SwitchToPhysicalCardParams = IdIdentifier & {
  cardNumber: string;
};

export type MesraTransactionItem = {
  id: string;
  title: string;
  createdAt: string;
  amount: number;
  status: TransactionStatuses;
  type: TransactionTypes;
  receiverBalance: number;
  senderBalance: number;
  issuedBy: MesraIssuer;
  updatedAt: string;
  merchantName: string;
  receiverCardNumber: number;
  senderCardNumber: number;
};

export type MesraTransaction = {
  data: MesraTransactionItem[];
  nextToken: string;
};

export type GetTransactionsParams = {
  nextToken?: string;
  limit?: number;
  statuses?: TransactionStatuses;
  types?: TransactionTypes | TransactionTypes[];
};
