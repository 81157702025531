import cn from 'classnames';
import { FadeInDown } from '~/components/Transition';
import { Image, useVisibility } from '~/shared';
import { DealPrice, DealImage } from '~/features/Deal';
import { DealRedeemProps } from './DealRedeem';
import { RedeemButton } from './RedeemButton';
import { VisibilityName } from '~/store';

export type DealRedeemTopBarProps = DealRedeemProps & {
  title: string;
  image: Image;
  isSoldOut: boolean;
};

export const DealRedeemTopBar = ({
  title,
  price,
  buttonText,
  isPurchasing,
  purchaseError,
  image,
  isSoldOut,
  isRedemptionCriteriaUnlocked,
  mersaCardStatus,
}: DealRedeemTopBarProps) => {
  const { isOpen } = useVisibility(VisibilityName.REDEEM_BAR);
  return (
    <FadeInDown show={isOpen}>
      <div className="hidden md:block fixed left-0 right-0 z-30 top-from-header bg-white shadow">
        <div className="container mx-auto flex justify-between items-center">
          <div
            className={cn('flex', {
              'w-3/4': !purchaseError,
              'w-1/4': purchaseError,
            })}
          >
            <div className="w-16">
              <div className="aspect-w-4 aspect-h-3">
                <DealImage
                  width={80}
                  quality={75}
                  src={image.url}
                  className="rounded-lg"
                />
              </div>
            </div>
            <div className="ml-4 w-2/3">
              <p className="text-gray-dark truncate">{title}</p>
              <DealPrice {...price} />
            </div>
          </div>
          {purchaseError && (
            <span className="text-error mr-8">
              {/* @ts-expect-error */}
              {purchaseError?.message}
            </span>
          )}
          <RedeemButton
            isSoldOut={isSoldOut}
            isLoading={isPurchasing}
            size="small"
            isRedemptionCriteriaUnlocked={isRedemptionCriteriaUnlocked}
            mersaCardStatus={mersaCardStatus}
            price={price}
          >
            {buttonText}
          </RedeemButton>
        </div>
      </div>
    </FadeInDown>
  );
};
