import cn from 'classnames';
import { Gallery, GalleryProps } from '~/components';
import { useVisibility } from '~/shared';
import { VisibilityName } from '~/store';
import { useHeaderView } from './hooks';
import { SoldOutOverlay } from '../SoldOutOverlay';
import { DealGalleryImage } from './DealGalleryImage';

export type DealGalleryProps = GalleryProps & {
  isSoldOut: boolean;
};

export const DealGallery = ({ images, isSoldOut }: DealGalleryProps) => {
  const { isOpen } = useVisibility(VisibilityName.REDEEM_BAR);
  const setTargetForHeaderView = useHeaderView();

  return (
    <div
      className={cn('md:flex-grow md:sticky md:h-128 md:w-56 md:max-w-xl', {
        'top-from-header-with-margin': !isOpen,
        'top-from-header-with-margin-and-notification': isOpen,
      })}
    >
      <div className="flex flex-col-reverse md:flex-col">
        <div className="mx-4 mt-4 md:mt-0 md:mx-0 mb-4 text-petronas-brand bg-petronas-brand-100 md:w-full p-2 rounded-lg text-regular">
          Exclusively for PETRONAS Mesra Loyalty Programme Members
        </div>
        <Gallery
          extra={<SoldOutOverlay rounded isSoldOut={isSoldOut} />}
          images={images}
          imageComponent={DealGalleryImage}
        />
      </div>
      <div ref={setTargetForHeaderView} />
    </div>
  );
};
