import { useQuery } from 'react-query';
import { getDealById } from '~/domain/deal';
import {
  ApiAxiosError,
  getErrorStatusCode,
  defaultOnError,
} from '~/domain/error';
import { HttpStatus } from '~/shared/httpStatus';
import { RootNavigationRoute } from '~/features/Navigation';
import { useIsAuthorized } from '~/features/Auth';
import { useRouter } from '~/shared/hooks';
import { useParams } from '@remix-run/react';

export const queryDetails = {
  queryKey: 'getDealById',
  queryFn: getDealById,
} as const;

const useDealDetailsHook = () => {
  const isAuth = useIsAuthorized();
  const router = useRouter();
  const { dealId } = useParams();

  return useQuery(
    [queryDetails.queryKey, dealId, isAuth],
    () => queryDetails.queryFn(dealId as string, { isPublic: !isAuth }),
    {
      onError: (error: ApiAxiosError) => {
        if (getErrorStatusCode(error) === HttpStatus.NOT_FOUND) {
          router.replace(RootNavigationRoute.HOME);
          return;
        }

        defaultOnError(error);
      },
    }
  );
};

export const useDealDetails = Object.assign(useDealDetailsHook, queryDetails);
