import * as React from 'react';
import { Loader } from '@setel/web-ui';
import cx from 'classnames';
import { defaultOnError } from '~/domain/error';
import { listBatchOutlets } from '~/domain/voucherBatch';
import { Outlet } from '~/domain/outlet';
import { useInfiniteQuery } from 'react-query';
import { DealOutlet } from './DealOutlet';
import { useIntersectionObserver } from '~/shared/hooks';

type DealOutletsProps = {
  voucherBatchId: string;
  selected?: null | Outlet;
  onSelect?: (outlet: Outlet) => void;
};

export const DealOutlets = ({
  voucherBatchId,
  selected,
  onSelect,
}: DealOutletsProps) => {
  const {
    data,
    fetchNextPage: fetchMore,
    hasNextPage: canFetchMore,
    isLoading,
    isFetchingNextPage: isFetchingMore,
  } = useInfiniteQuery(
    ['listBatchOutlets', voucherBatchId],
    ({ pageParam: pageToken = '1' }: { pageParam?: string }) =>
      listBatchOutlets({ pageToken, voucherBatchId }),
    {
      onError: defaultOnError,
      getNextPageParam: (last) => last.nextPageToken,
    }
  );

  const pages = data?.pages;

  const canFetch = Boolean(canFetchMore);
  const loadMore = React.useCallback(
    ({ isIntersecting }: any) => canFetch && isIntersecting && fetchMore(),
    [canFetch, fetchMore]
  );
  const setIntersectionTarget = useIntersectionObserver({
    callback: loadMore,
  });

  const isFirstLoad = (isLoading || isFetchingMore) && !pages?.length;

  return (
    <div
      className={cx(
        'divide-y divide-gray-400',
        // on first load, align Loader dead center
        isFirstLoad && 'h-full flex justify-center items-center'
      )}
    >
      {pages?.flatMap(({ data }) =>
        data.map((outlet) => (
          <DealOutlet
            key={outlet._id}
            name={outlet.name}
            address={outlet.address}
            selected={selected?._id === outlet._id}
            onSelect={onSelect ? () => onSelect(outlet) : undefined}
          />
        ))
      )}
      {(isLoading || isFetchingMore) && (
        <div className="flex justify-center py-6">
          <Loader type="spinner-blue" />
        </div>
      )}
      <button className="opacity-0" ref={setIntersectionTarget} />
    </div>
  );
};
