import { getCard } from '~/domain/card';
import { defaultOnError } from '~/domain/error';
import { useIsAuthorized } from '~/features/Auth';
import { useQuery } from 'react-query';

export const useMersaCard = () => {
  const isAuth = useIsAuthorized();

  const { data } = useQuery('getCard', getCard, {
    onError: defaultOnError,
    enabled: isAuth,
  });

  return data;
};
