import { apiClient } from '~/shared/axios';
import { BadgeDetails, BadgeGalleryResponse } from './badge.type';

export const listUserBadges = (language: string) =>
  apiClient
    .get<BadgeGalleryResponse>('/rewards/user-badges', {
      headers: {
        'Accept-Language': language,
      },
    })
    .then((resp) => resp.data);

export const getUserBadge = (badgeId: string, periodIndex?: number) =>
  apiClient
    .get<BadgeDetails>(`/rewards/user-badges/${badgeId}`, {
      ...(periodIndex && { params: { periodIndex } }),
    })
    .then((resp) => resp.data);
