export const CircularSizeMapping = (size: number) => ({
  size: size + 16,
  stroke: 4,
});

type CircularProgressProps = {
  size: number;
  progress: number;
  strokeWidth: number;
  circleOneStroke?: string;
  circleTwoStroke?: string;
};
export const CircularProgress = ({
  size,
  progress,
  strokeWidth,
  circleOneStroke = '#EAEBEE',
  circleTwoStroke = '#00B0FF',
}: CircularProgressProps) => {
  const center = size / 2;
  const radius = size / 2 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = ((100 - progress) / 100) * circumference;

  return (
    <svg
      className="absolute left-1/2 transform -translate-x-1/2"
      width={size}
      height={size}
    >
      <circle
        stroke={circleOneStroke}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        style={{ fill: 'none' }}
      />
      <circle
        stroke={circleTwoStroke}
        cx={center}
        cy={center}
        r={radius}
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        transform={`rotate(-90, ${center}, ${center})`}
        style={{ fill: 'none' }}
      />
    </svg>
  );
};
