import { getUserBadge, listUserBadges } from '~/domain/badge';
import {
  ApiAxiosError,
  defaultOnError,
  getErrorStatusCode,
} from '~/domain/error';
import { RootNavigationRoute } from '~/features/Navigation';
import { useQuery } from 'react-query';
import { HttpStatus, useRouter } from '~/shared';

const USE_BADGE_GALLERY = 'badge-gallery-list';
const USE_BADGE_DETAILS = 'badge-gallery-details';

export const useBadgeGallery = (language: string) => {
  const { data: response, ...query } = useQuery([USE_BADGE_GALLERY], (_) =>
    listUserBadges(language)
  );

  return {
    data: response,
    ...query,
    hasBadges: response?.data?.length,
  };
};

export const useBadgeDetails = (badgeId: string, periodIndex?: number) => {
  const router = useRouter();
  return useQuery(
    [USE_BADGE_DETAILS, badgeId, periodIndex],
    () => getUserBadge(badgeId, periodIndex),
    {
      enabled: Boolean(badgeId),
      onError: (error: ApiAxiosError) => {
        if (getErrorStatusCode(error) === HttpStatus.NOT_FOUND) {
          router.replace(RootNavigationRoute.HOME);
          return;
        }

        defaultOnError(error);
      },
    }
  );
};
