import { FC } from 'react';
import * as React from 'react';
import cn from 'classnames';
import { DealRedemptionCriteriaInfo } from '~/domain/deal';
import { Size } from '~/components/theme';

export const SIZE_STYLE: Record<Size, string> = {
  [Size.BIG]: 'text-regular py-2 px-3',
  [Size.BASE]: 'text-badge py-1 px-2',
  [Size.SMALL]: 'text-link py-1 px-2',
};

export type DealRedemptionCriteriaProps = DealRedemptionCriteriaInfo & {
  className?: string;
  size?: Size;
};

export const DealRedemptionCriteria: FC<
  React.PropsWithChildren<DealRedemptionCriteriaProps>
> = ({ className, size = Size.BASE, campaign }) => {
  const requiredCriteriaName = campaign?.name;

  if (!requiredCriteriaName) return null;

  return (
    <div className={className}>
      <span
        className={cn(
          'inline-flex items-center leading-tight uppercase tracking-wide font-semibold border rounded max-w-full',
          'text-purple border-purple-100',
          SIZE_STYLE[size]
        )}
      >
        <span className="truncate">{requiredCriteriaName}</span>
      </span>
    </div>
  );
};
