import * as React from 'react';
import {
  DealRedemptionCriteriaInfo,
  getDealRedemptionCriteriaDisplay,
} from '~/domain/deal';
import { RedemptionCriteriaLearnMoreButton } from './RedemptionCriteriaLearnMoreButton';

export type RedemptionCriteriaExplainerProps = {
  isRedemptionCriteriaUnlocked: boolean;
  redemptionCriteria?: DealRedemptionCriteriaInfo;
  showLearnMore?: boolean;
  className?: string;
};

export const RedemptionCriteriaExplainer: React.FC<
  React.PropsWithChildren<RedemptionCriteriaExplainerProps>
> = ({
  isRedemptionCriteriaUnlocked,
  redemptionCriteria,
  showLearnMore,
  className = '',
}) => {
  if (isRedemptionCriteriaUnlocked || !redemptionCriteria) {
    return null;
  }

  const criteria = getDealRedemptionCriteriaDisplay(redemptionCriteria);

  return (
    <p className={className}>
      Earn {criteria?.text} {criteria?.type} to redeem this deal.{' '}
      {showLearnMore && (
        <RedemptionCriteriaLearnMoreButton
          forMobile={false}
          redemptionCriteria={redemptionCriteria}
        />
      )}
    </p>
  );
};
