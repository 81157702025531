import type { ComponentPropsWithoutRef } from 'react';
import { type CDNImageModifiers, useCDNImageUrl } from '~/lib/cdn';

export interface DealImageProps
  extends Omit<ComponentPropsWithoutRef<'img'>, 'width'>,
    CDNImageModifiers {
  src: string;
  priority?: boolean;
}

export function DealImage({
  src,
  className = '',
  width,
  quality,
  priority,
  ...props
}: DealImageProps) {
  const imgSrc = useCDNImageUrl(src, { width, quality });
  return (
    <img
      src={imgSrc}
      className={`block w-full object-cover bg-deal-thumbnail ${className}`}
      decoding="async"
      loading={priority ? 'eager' : 'lazy'}
      {...props}
    />
  );
}
