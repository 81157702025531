import { PaginationParams, PaginationResponse } from '~/shared';
import { apiClient } from '~/shared/axios';
import { Outlet } from '~/domain/outlet';

export const listBatchOutlets = ({
  voucherBatchId,
  pageToken,
  perPage,
}: PaginationParams & { voucherBatchId: string }) => {
  return apiClient
    .get<PaginationResponse<Outlet>>(
      `/vouchers/vouchers-batch/${voucherBatchId}/outlets`,
      {
        public: true,
        params: {
          pageToken,
          perPage,
        },
      }
    )
    .then((resp) => resp.data);
};
