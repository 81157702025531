import { useQuery } from 'react-query';
import { getMesraBalance } from './card.service';
import { AxiosError } from 'axios';
import { defaultOnError } from '~/domain/error';

export const useGetMesraBalance = () =>
  useQuery('getMesraBalance', getMesraBalance, {
    onError: defaultOnError,
    retry: (failureCount, error) => {
      const statusCode = (error as AxiosError).response?.status;
      return (statusCode === 400 || statusCode === 404) && failureCount <= 4;
    },
    retryDelay: () => 10000,
  });
