import cn from 'classnames';
import type { GalleryImageProps } from '~/components/Slider/Gallery/GalleryImage';
import { DealImage } from '../DealImage';

export const DealGalleryImage = ({
  url,
  isActive,
  isPagination,
}: GalleryImageProps) => (
  <div
    className={cn(
      'md:border border-opacity-10 border-gray-light md:rounded-lg',
      'aspect-w-4 aspect-h-3',
      { 'mt-2': isPagination, 'shadow-gallery': isActive }
    )}
  >
    <DealImage
      priority={!isPagination}
      width={isPagination ? 100 : 640}
      quality={isPagination ? 75 : 90}
      src={url}
      className="md:rounded-lg"
    />
  </div>
);
