import { ArrowLeftIcon } from '@setel/web-ui';
import cn from 'classnames';
import { IconButton } from '~/components';
import { useRouter, useVisibility } from '~/shared';
import { VisibilityName } from '~/store/visibility';

type DealHeaderProps = { title: string };

export const DealHeader = ({ title }: DealHeaderProps) => {
  const router = useRouter();
  const { isOpen: fullHeader } = useVisibility(
    VisibilityName.MOBILE_DEAL_HEADER
  );
  const animation = 'transition duration-500';
  return (
    <div
      className={cn(
        'sticky top-0 z-10 h-[60px] mb-[-60px] w-screen px-4 flex-none flex items-center',
        'md:hidden',
        animation,
        {
          'shadow bg-white': fullHeader,
        }
      )}
    >
      <IconButton
        className={cn('absolute', {
          animation,
          'text-white': !fullHeader,
          'text-gray-dark': fullHeader,
        })}
        onClick={router.back}
        children={<ArrowLeftIcon />}
      />
      <p
        className={cn(
          'flex-auto px-10 font-medium text-lg truncate',
          animation,
          {
            'text-transparent': !fullHeader,
            'text-gray-dark': fullHeader,
          }
        )}
      >
        {title}
      </p>
    </div>
  );
};
