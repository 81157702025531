import { useCallback, useEffect, useRef, useState } from 'react';
import { PhoneInput, phoneValidation } from '~/components/Form/PhoneInput';
import { ProgressButton } from '~/components/Button/progress-button';
import type { ValidationError as YupValidationError } from 'yup';
import { sendDownloadLink } from '~/domain/sms';
import { ApiAxiosError, defaultOnError } from '~/domain/error';
import { sendDownloadViaSMSEvent, SMSMeTheAppEvent } from '~/shared';
import { useTranslation } from 'react-i18next';

export const DownloadViaSMSForm = ({
  id,
  analyticEvent,
}: {
  id?: string;
  analyticEvent?: SMSMeTheAppEvent;
}) => {
  const phoneInput = useRef<HTMLInputElement>(null);
  const submitBtn = useRef<HTMLButtonElement>(null);

  const [phone, setPhone] = useState('');
  const [submitState, setSubmitState] = useState<
    'submitting' | 'done' | undefined
  >();

  const onChange = useCallback((value: string) => {
    setPhone(value);
  }, []);

  const validate = useCallback(
    (value: string) => {
      try {
        phoneValidation.validateSync(value);
        phoneInput.current?.setCustomValidity('');
      } catch (err) {
        const { errors } = err as YupValidationError;
        const [errMsg] = errors;
        phoneInput.current?.setCustomValidity(errMsg);
      }
    },
    [phone]
  );

  let { t } = useTranslation();

  useEffect(() => {
    validate(phone);
  }, [phone]);

  return (
    <form
      id={id}
      className="flex items-stretch"
      onSubmit={async (e) => {
        e.preventDefault();

        if (analyticEvent) {
          sendDownloadViaSMSEvent(analyticEvent, phone, { formId: id });
        }

        try {
          setSubmitState('submitting');
          await sendDownloadLink(phone);
          setSubmitState('done');
        } catch (err) {
          let { message } = err as ApiAxiosError;
          if (Array.isArray(message)) {
            message = message.pop();
          }
          if (message) {
            phoneInput.current?.setCustomValidity(message);
            // click submit again to trigger msg
            setTimeout(() => {
              submitBtn.current?.click();
            }, 0);
          }
          defaultOnError(err);
          setSubmitState(undefined);
        }
      }}
    >
      <PhoneInput
        aria-label="Your phone number"
        name="phone"
        className="flex-auto"
        inputClass="!pl-[80px] rounded-r-none rounded-l-lg border-gray-200"
        ref={phoneInput}
        value={phone}
        onChange={onChange}
        bypassValidity
        disabled={submitState === 'submitting'}
      />

      <ProgressButton
        ref={submitBtn}
        type="submit"
        className="flex-none h-auto rounded-l-none rounded-r-lg"
        isLoading={submitState === 'submitting'}
        isSuccess={submitState === 'done'}
        afterSuccess={() => setSubmitState(undefined)}
      >
        {t('footerSMSForm.downloadViaSMSForm')}
      </ProgressButton>
    </form>
  );
};
