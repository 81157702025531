import cn from 'classnames';
import { DealPrice as DealPriceType } from '~/domain/deal';
import { FC } from 'react';
import * as React from 'react';
import { CurrentPrice } from './CurrentPrice';
import { UnitLabel } from './UnitLabel';
import { useTranslation } from 'react-i18next';

export type DealPriceProps = DealPriceType & {
  twoLine?: boolean;
};

export const DealPrice: FC<React.PropsWithChildren<DealPriceProps>> = ({
  twoLine,
  ...price
}) => {
  const { t } = useTranslation();

  return price.isDiscountPriceFree ? (
    <strong className="font-medium text-petronas-brand md:text-2xl">
      {t('dealPrice.free')}
    </strong>
  ) : (
    <div
      className={cn('flex', {
        'flex-col': twoLine,
        'items-center': !twoLine,
      })}
    >
      <CurrentPrice {...price} twoLine={twoLine} />
      <div
        className={cn('text-gray-light', {
          'text-sm': !twoLine,
          'text-base line-through': twoLine,
        })}
      >
        {price.isDiscountPriceAvailable && (
          <span className={cn('line-through')}>
            {new Intl.NumberFormat().format(price.amount)}&nbsp;
          </span>
        )}

        {(!twoLine || price.isDiscountPriceAvailable) && (
          <UnitLabel unit={price.unit} />
        )}
      </div>
    </div>
  );
};
