import type { FC } from 'react';

export type DealSummaryProps = {
  title: string;
  merchantName: string;
  summary: string;
};

export const DealSummary: FC<React.PropsWithChildren<DealSummaryProps>> = ({
  title,
  merchantName,
  summary,
}) => {
  return (
    <>
      <h1 className="text-xl md:text-3xl text-gray-dark font-medium leading-snugs mb-4">
        {title}
      </h1>
      <p className="text-regular text-gray-light mb-4">{merchantName}</p>
      <p className="text-gray-dark">{summary}</p>
    </>
  );
};
