import { PetronasButton, PetronasButtonProps } from '~/components';
import { MesraStatus } from '~/domain/card';
import { DealPrice as DealPriceType } from '~/domain/deal';
import { useIsAuthorized } from '~/features/Auth';
import { useCallback } from 'react';
import { useVisibility } from '~/shared';
import { VisibilityName } from '~/store/visibility';
import { runTimeConfig as config } from '~/shared/runtime-config';

type RedeemButtonProps = PetronasButtonProps & {
  isSoldOut: boolean;
  isRedemptionCriteriaUnlocked: boolean;
  mersaCardStatus?: MesraStatus;
  price: DealPriceType;
};

export const RedeemButton = ({
  mersaCardStatus,
  children,
  isSoldOut,
  isRedemptionCriteriaUnlocked,
  price,
  ...props
}: RedeemButtonProps) => {
  const isAuth = useIsAuthorized();
  const confirmRedeem = useVisibility(VisibilityName.REDEEM_CONFIRMATION);
  const auth = useVisibility(VisibilityName.AUTH);
  const activateMesra = useVisibility(VisibilityName.ACTIVATE_MESRA);

  const onClick = useCallback(() => {
    if (!isAuth) return auth.open();
    if (!price.isDiscountPriceFree && mersaCardStatus !== MesraStatus.ACTIVE) {
      return activateMesra.open();
    }
    confirmRedeem.open();
  }, [auth, activateMesra, confirmRedeem, mersaCardStatus, isAuth]);

  return (
    <PetronasButton
      disabled={
        config.ENABLE_MAINTENANCE_MODE ||
        isSoldOut ||
        !isRedemptionCriteriaUnlocked ||
        mersaCardStatus === MesraStatus.FROZEN ||
        mersaCardStatus === MesraStatus.TEMPORARILY_FROZEN
      }
      onClick={onClick}
      {...props}
    >
      {config.ENABLE_MAINTENANCE_MODE
        ? 'UNDER MAINTENANCE'
        : isSoldOut
        ? 'Sold out'
        : children}
    </PetronasButton>
  );
};
