import cn from 'classnames';
import type {
  DealPrice as DealPriceType,
  DealRedemptionCriteriaInfo,
} from '~/domain/deal';
import { ApiRawError } from '~/domain/error';
import { forwardRef } from 'react';
import { DealPrice } from '../../DealPrice';
import { RedemptionCriteriaExplainer } from './RedemptionCriteriaExplainer';
import { RedeemButton } from './RedeemButton';
import { MesraStatus } from '~/domain/card';

export type DealRedeemProps = {
  buttonText: string;
  price: DealPriceType;
  isPurchasing: boolean;
  isSoldOut: boolean;
  forMobile?: boolean;
  purchaseError?: ApiRawError;
  redemptionCriteria?: DealRedemptionCriteriaInfo;
  isRedemptionCriteriaUnlocked: boolean;
  mersaCardStatus?: MesraStatus;
};

export const DealRedeem = forwardRef<HTMLDivElement, DealRedeemProps>(
  function DealRedeem(
    {
      buttonText,
      price,
      forMobile,
      purchaseError,
      isPurchasing,
      isSoldOut,
      isRedemptionCriteriaUnlocked,
      redemptionCriteria,
      mersaCardStatus,
    },
    ref
  ) {
    return (
      <div
        ref={ref}
        className={cn('w-full bottom-0 py-3 bg-white', {
          'block md:hidden fixed shadow-top px-4': forMobile,
          'hidden md:block border-t border-b mt-4': !forMobile,
        })}
      >
        <div
          className={cn({
            'flex flex-wrap': !forMobile,
          })}
        >
          {isRedemptionCriteriaUnlocked && (
            <div className={cn({ 'flex-col my-auto order-last': !forMobile })}>
              <DealPrice {...price} twoLine={!forMobile} />
            </div>
          )}
          <RedemptionCriteriaExplainer
            className={cn({ 'order-first flex-auto': !forMobile })}
            showLearnMore={true}
            isRedemptionCriteriaUnlocked={isRedemptionCriteriaUnlocked}
            redemptionCriteria={redemptionCriteria}
          />
          <RedeemButton
            isSoldOut={isSoldOut}
            isLoading={isPurchasing}
            className={forMobile ? 'w-full mt-2' : 'mr-6 my-2'}
            size={forMobile ? 'large' : 'medium'}
            isRedemptionCriteriaUnlocked={isRedemptionCriteriaUnlocked}
            mersaCardStatus={mersaCardStatus}
            price={price}
          >
            {buttonText}
          </RedeemButton>
        </div>
        {purchaseError && (
          <p className="text-error">
            {/* @ts-expect-error */}
            {purchaseError?.message}
          </p>
        )}
      </div>
    );
  }
);
