import { useCallback, useState } from 'react';
import { Button, ButtonProps, BareButton } from '@setel/web-ui';
import type { DealRedemptionCriteriaInfo } from '~/domain/deal';
import { useVisibility } from '~/shared';
import { VisibilityName } from '~/store';
import { useIsAuthorized } from '~/features/Auth';
import { BadgeModal } from '~/features/Badge/BadgeModal';

type RedemptionCriteriaLearnMoreButtonProps = {
  forMobile?: boolean;
  redemptionCriteria?: DealRedemptionCriteriaInfo;
} & ButtonProps;

export const RedemptionCriteriaLearnMoreButton = ({
  redemptionCriteria,
  forMobile,
  ...props
}: RedemptionCriteriaLearnMoreButtonProps) => {
  const isAuth = useIsAuthorized();
  const auth = useVisibility(VisibilityName.AUTH);
  const [openModal, setOpenModal] = useState(false);

  const onClick = useCallback(async () => {
    // open auth modal
    if (!isAuth) {
      return auth.open();
    }
    // open criteria modal
    setOpenModal(true);
  }, [isAuth, auth]);

  const badgeIdOfCampaign = redemptionCriteria?.campaign?.linkedBadges?.[0]?.id;
  const badgeId = redemptionCriteria?.badge?.id;

  return (
    <>
      {openModal && badgeIdOfCampaign && (
        <BadgeModal
          badgeId={badgeIdOfCampaign}
          onClose={() => setOpenModal(false)}
        />
      )}
      {openModal && badgeId && (
        <BadgeModal badgeId={badgeId} onClose={() => setOpenModal(false)} />
      )}

      {forMobile ? (
        <Button variant="outline" onClick={onClick} {...props}>
          Learn more
        </Button>
      ) : (
        <BareButton className="inline-block" onClick={onClick}>
          Learn more
        </BareButton>
      )}
    </>
  );
};
