import { Size } from '~/components/theme';
import * as Card from '../../components/Card';
import { Deal as DealType } from '../../domain/deal';
import { DealRedemptionCriteria } from './DealDetails/DealRedemptionCriteria';
import { DealPrice } from './DealPrice';
import { SoldOutOverlay } from './SoldOutOverlay';
import { DealImage } from './DealImage';

export type DealProps = DealType;

const MAX_TITLE_LENGTH = {
  all: 30,
  xl: 40,
};

const truncateTitle = (title: string, size: number): string =>
  title.length > size ? `${title.substr(0, size)}...` : title;

export const Deal = ({
  leftCount,
  price,
  content,
  voucherBatch,
  redemptionCriteriaInfo,
}: DealProps) => {
  const { title } = voucherBatch.content;
  const { name: merchantName } = voucherBatch.merchant || {};
  const { url: coverImage } = content.images[0];
  return (
    <Card.Card className="h-full overflow-hidden">
      <div className="relative">
        <div className="aspect-w-4 aspect-h-3">
          <DealImage src={coverImage} width={320} quality={75} />
        </div>
        <SoldOutOverlay isSoldOut={leftCount === 0} />
      </div>
      <Card.CardContent className="h-full flex flex-col justify-between">
        <div>
          {redemptionCriteriaInfo && (
            <DealRedemptionCriteria
              className="mb-3"
              size={Size.SMALL}
              {...redemptionCriteriaInfo}
            />
          )}
          <Card.CardTitle className="xl:hidden h-12 leading-6 overflow-hidden pb-2">
            {truncateTitle(title ?? '-', MAX_TITLE_LENGTH.all)}
          </Card.CardTitle>
          <Card.CardTitle className="hidden xl:block">
            {truncateTitle(title ?? '-', MAX_TITLE_LENGTH.xl)}
          </Card.CardTitle>
          {merchantName && (
            <Card.CardDescription>{merchantName}</Card.CardDescription>
          )}
        </div>
        <DealPrice {...price} />
      </Card.CardContent>
    </Card.Card>
  );
};
